export default function(to, from, next) {
  if (from.name !== "Start") {
    const __noTable =
        window.store.getters["Table/table"] === undefined ||
        window.store.getters["Table/table"] === null ||
        window.store.getters["Table/table"] === "",
      __noParty =
        window.store.getters["Table/party"] === undefined ||
        window.store.getters["Table/party"] === null ||
        window.store.getters["Table/party"] === "";

    if (__noTable || __noParty || (__noTable && __noParty)) {
      next("/");
    }
  }

  next();
}
