<template>
  <v-dialog :overlay-opacity="0.9" v-model="visible" max-width="600" persistent>
    <v-card>
      <v-card-title>
        {{ this.$t("generic.lang_were_offline") }}
      </v-card-title>

      <v-card-text v-if="this.Messages">
        <div v-html="this.Messages.offline_message"></div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

export default {
  name: "OfflineMessageComponent",
  computed: {
    ...createNamespacedHelpers("Config").mapGetters([
      "is_available",
      "Messages"
    ]),
    visible: {
      get() {
        return !this.is_available;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<style scoped></style>
