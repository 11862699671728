"use strict";

import Vue from "vue";
import axios from "axios";

let config = {
  baseURL: process.env.VUE_APP_API_HOST,
  responseType: "json",
  headers: {
    common: {
      "Content-Type": "application/x-www-form-urlencoded",
      language: localStorage.getItem("lang") || "de"
    }
  }
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    /*        if (localStorage.getItem('lang') !== null)
                    config.headers.common['language'] = "de";
                else config.headers.common['language'] = localStorage.getItem('lang')*/
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    window.store.commit("loader/show", false);

    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue /*, options*/) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    }
  });
};

Vue.use(Plugin);

export default Plugin;
