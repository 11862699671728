import Vue from "vue";
import Vuex from "vuex";

/*import vuex modules*/
/**
 * table
 */
import Table from "@/store/modules/Table";
import Order from "@/store/modules/Order";
import Filter from "@/store/modules/Filter";
import Config from "@/store/modules/config/index";
import Loader from "@/store/modules/Loader/index";

/**
 * vuex persist
 */
import VuexPersistence from "vuex-persist";

const vuexLocal = new VuexPersistence({
  storage: window.sessionStorage,
  reducer: state => ({
    Table: state.Table,
    Order: state.Order,
    Config: state.Config
  })
});

Vue.use(Vuex);

const Store = new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    /**
     * LocalBee_Table
     */
    Table,
    Order,
    Filter,
    Config,
    Loader
  },
  plugins: [vuexLocal.plugin]
});

window.store = Store;
export default Store;
