/**
 * Filter state allow us to share selected filters between components
 * */

const Filter = {
  namespaced: true,
  state: {
    Search: "",
    Price: [0, 100] /*price range*/,
    SortBy: [] /*sort by name, prices*/,
    Allergens: [],
    MealTypes: []
  },
  getters: {
    search: state => state.Search,
    price: state => state.Price,
    sortBy: state => state.SortBy,
    allergens: state => state.Allergens,
    mealTypes: state => state.MealTypes,
    sorts: () => [
      {
        name: "name",
        value: "name"
      },
      {
        name: "price",
        value: "price"
      }
    ],
    params: state => {
      return {
        "filter[min_price]": state.Price[0],
        "filter[max_price]": state.Price[1],
        "filter[allergens]": state.Allergens,
        sort: state.SortBy,
        "filter[meal_types]": state.MealTypes,
        "filter[active]": true,
        "filter[search]": state.Search
      };
    }
  },
  mutations: {
    setSearch(state, val) {
      state.Search = val;
    },
    setSortBy(state, val) {
      state.SortBy = val;
    },
    setPrice(state, payload = [0, 100]) {
      state.Price = payload;
    },
    setAllergens(state, payload) {
      state.Allergens = payload;
    },
    setMealTypes(state, payload) {
      state.MealTypes = payload;
    }
  }
};

export default Filter;
