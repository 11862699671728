<template>
  <v-card class="pa-0 fill-height">
    <v-card-title class="pa-0">
      <v-btn
        @click="$router.push('/menu')"
        min-width="40"
        max-width="40"
        min-height="40"
        max-height="40"
        id="back_btn"
        class="pa-0 px-0 mt-1 ml-0"
        color="#ffffffab"
        small
        elevation="0"
      >
        <v-icon large color="primary" class="pa-0">
          mdi-chevron-left
        </v-icon>
      </v-btn>

      <span class="text-muted pt-1">{{
        $t("generic.lang_privacy_and_policy")
      }}</span>
    </v-card-title>

    <v-card-text class="pt-2">
      <div v-html="this.privacy"></div>
    </v-card-text>
  </v-card>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

export default {
  name: "index",
  computed: {
    ...createNamespacedHelpers("Config").mapGetters(["privacy"])
  }
};
</script>

<style scoped></style>
