<template>
  <v-container fill-height class="">
    <!--    <v-container>
          <language-switcher-widget/>
        </v-container>-->
    <!-- Start First Step = Welcome Page-->
    <div class="text-right w-100 d-flex">
      <v-spacer />
      <h6 class="py-2 mx-auto px-5 my-4 font-weight-bold">
        {{ $t("generic.lang_tableNo") }} {{ this.table }}
      </h6>
    </div>

    <v-container class="justify-center align-center text-center">
      <v-container fill-height class="justify-center align-center text-center">
        <div>
          <div
            v-if="hideNameForm === true"
            v-html="this.Messages.start_page"
          ></div>
          <div v-else v-html="this.Messages.ask_for_name_page"></div>

          <div v-show="hideImgAndBtn === false">
            <img
              v-if="hideImage === false"
              :src="this.Images.welcome_page_logo || '@/assets/img/Bee.gif'"
              height="200px"
            />
            <br />

            <v-btn
              v-if="hideButton === false"
              class="px-14 mt-3 py-8 rounded-pill mb-5 text-weight-bold text-capitalize"
              color="primary"
              style="font-size: 1.2em;"
              elevation="0"
              v-on:click="nameTextBox()"
            >
              {{ $t("generic.lang_orderNow") }}
            </v-btn>
          </div>

          <v-sheet
            v-if="hideNameForm === false"
            elevation="0"
            tile
            color="transparent"
            height="200px"
          ></v-sheet>
        </div>
        <!-- Start First Step = Welcome Page-->

        <!--Start Show Card NameTextBox-->
        <v-card
          v-if="hideNameForm === false"
          elevation="0"
          height="300px"
          width="100%"
          color="secondary"
          class="pt-15 px-12"
          style="border-top-right-radius: 16%; border-top-left-radius: 16%; position: fixed; bottom: 0%;"
        >
          <h4 class="primary--text mb-5 text-left">
            {{ $t("generic.lang_whatsYourName") }}
          </h4>
          <v-form @submit.prevent="() => false" lazy-validation ref="name_form">
            <v-text-field
              v-model="party"
              :placeholder="$t('generic.lang_name')"
              class="rounded-lg"
              outlined
              :rules="[v => !!v]"
              rounded
              v-on:keyup.enter="setParty()"
            >
            </v-text-field>
            <v-btn
              @click="setParty()"
              :disabled="!!!party || isLoading"
              :loading="isLoading"
              elevation="0"
              block
              large
              color="primary"
            >
              {{ $t("generic.lang_next") }}
            </v-btn>
          </v-form>
        </v-card>
        <!--End Show Card NameTextBox-->
      </v-container>
      <v-footer v-if="hideNameForm" class="text-center mt-12 transparent">
        <div v-if="hideNameForm" class="text-center w-100">
          <v-btn
            v-show="$i18n.locale !== lang"
            v-for="lang in languages"
            :key="lang"
            @click="changeLocale(lang)"
            text
          >
            <flag style="font-size: 30px" :iso="flags[lang]" />
          </v-btn>
          <!--        <v-btn @click="changeLocale('de')" v-if="this.language === 'en'" text>
                    <flag style="font-size: 30px" iso="de"/>
                  </v-btn>-->
        </div>
        <div class="text-center font-weight-light w-100 text-center mt-3">
          <span
            class="text-muted font-size-md"
            style="font-size: 11px !important;"
          >
            made with ❤️️ by
            <span style="color: #f84611">3POS</span> Kassensystem
          </span>
        </div>
      </v-footer>

      <!--table not found dialog-->
      <v-dialog
        v-if="this.is_available"
        persistent
        v-model="tableNotFoundDialog"
        max-width="600"
      >
        <v-card class="text-center align-center">
          <v-card-title class="text-center">
            <v-avatar class="mx-auto" size="100" color="error">
              <v-icon color="white" x-large>
                mdi-alert-circle-outline
              </v-icon>
            </v-avatar>
          </v-card-title>
          <v-card-text>
            <div v-html="this.error_message" />
          </v-card-text>

          <v-card-actions>
            <v-spacer />
            <v-btn @click="quit" depressed text color="error">
              {{ this.$t("generic.lang_quit") }}
            </v-btn>

            <v-btn to="/" depressed color="primary">
              {{ this.$t("generic.lang_scan_table") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
//import LanguageSwitcherWidget from "@/components/Widget/LanguageSwitcherWidget.vue";

export default {
  name: "Start",
  components: {
    /*LanguageSwitcherWidget*/
  },
  data: () => ({
    hideButton: true,
    hideImage: true,
    hideNameForm: true,
    hideImgAndBtn: false,
    tableNotFoundDialog: false,
    reject: null,
    party: "",
    error_message: "",
    flags: {
      en: "gb",
      de: "de",
      fr: "fr",
      ar: "sa"
    }
  }),
  computed: {
    ...createNamespacedHelpers("Config").mapGetters([
      "Images",
      "Messages",
      "is_available",
      "languages",
      "defaultLanguage"
    ]),
    ...createNamespacedHelpers("Table").mapGetters(["table"]),
    ...createNamespacedHelpers("Loader").mapGetters(["isLoading"]),
    language: {
      get() {
        return this.$i18n.locale;
      },
      set(val) {
        this.$i18n.locale = val;
      }
    }
  },

  mounted() {
    //clear old data
    window.sessionStorage.clear();
    window.localStorage.clear();
    window.store.commit("Order/initialState");

    this.checkTableAvailability();
    this.startLoad();
  },

  methods: {
    async checkTableAvailability() {
      await new Promise((resolve, reject) => {
        this.axios
          .get(
            window.ENDPOINTS.TABLEPLANER.TABLE.FINDBYUUID(
              this.$route.params.table
            )
          )
          .then(res => {
            this.$store.commit("Table/setUuid", res.data.data.tablebee_uuid);
            this.$store.commit("Table/setTable", res.data.data.name);
            resolve(true);
          })
          .catch(() => {
            this.error_message = this.$t("generic.unavailable_table");
            this.tableNotFoundDialog = true;
            this.reject = reject;
          });
      });
    },
    quit() {
      window.close();
    },
    async changeLocale(locale) {
      this.$i18n.locale = locale;
      this.$axios.defaults.headers.common["language"] = locale;
      localStorage.setItem("lang", locale);
      await this.$store.dispatch("Config/loadConfig");
      this.$forceUpdate();
    },
    /*save scanned table in the vuex/localeStorage*/
    async setTable() {
      // QUICK FIX: TODO: @IZEM NEED TO CHANGE IT
      await this.axios
        .get(
          window.ENDPOINTS.TABLEPLANER.TABLE.FINDBYUUID(
            this.$route.params.table
          )
        )
        .then(res => {
          if (res.data.data.active === 0 || res.data.data.active === false) {
            this.error_message = this.Messages.inactive_table_message;
          } else this.error_message = this.$t("generic.unavailable_table");
          this.$store.commit("Table/setUuid", res.data.data.tablebee_uuid);
          this.$store.commit("Table/setTable", res.data.data.name);
        })
        .catch(() => {
          this.tableNotFoundDialog = false;
        });
    },
    /*save typed name as party name*/
    setParty() {
      /*check name*/
      if (!this.$refs.name_form.validate()) return;

      /*set party*/
      this.$store.commit("Table/setParty", this.party);

      /*redirect to Menu*/
      this.$router.push("/menu");
    },
    //That to show bee animation and button in start
    startLoad() {
      setInterval(() => {
        this.hideImage = false;
        setInterval(() => {
          this.hideButton = false;
        }, 2000);
      }, 2000);
    },

    //That to show NameTextBox Dialog and hide Jetzt Starten button
    nameTextBox() {
      /*save scanned table*/
      //this.setTable();
      this.hideImage = true;
      this.hideButton = true;
      this.hideNameForm = false;
      this.hideImgAndBtn = true;
    },

    //That to redirect to welcome After enter the name
    nameValidation() {
      this.$router.push({ name: "welcome" });
      localStorage.name = this.party;
    }
  }
};
</script>
