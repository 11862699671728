<template>
  <v-app
    v-bind:style="{
      fontFamily: this.FontFamily + ' !important'
    }"
  >
    <v-overlay color="white" z-index="4000" :value="this.isLoading">
      <v-progress-circular color="primary" indeterminate size="128" />
    </v-overlay>
    <v-main class="bg">
      <router-view></router-view>

      <!-- order finished dialog -->
      <v-dialog v-if="this.is_available" v-model="is_error" max-width="600">
        <v-card class="text-center align-center">
          <v-card-title class="text-center">
            <v-avatar class="mx-auto" size="100" color="Success">
              <v-icon color="white" x-large>
                mdi-check
              </v-icon>
            </v-avatar>
          </v-card-title>
          <v-card-text>
            {{ $t("generic.lang_thanksForVisiting") }}
          </v-card-text>

          <v-card-actions>
            <v-btn @click="quit" depressed text color="error">
              {{ $t("generic.lang_quit") }}
            </v-btn>
            <v-spacer />
            <v-btn @click="tryAgain" depressed color="primary">
              {{ $t("generic.lang_scanAgain") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- one of the orders is finished dialog -->
      <v-dialog v-model="an_order_finished" max-width="600">
        <v-card class="text-center align-center">
          <v-card-title class="text-center">
            <v-avatar class="mx-auto" size="100" color="Success">
              <v-icon color="white" x-large>
                mdi-check
              </v-icon>
            </v-avatar>
          </v-card-title>
          <v-card-text>
            {{ $t("generic.lang_an_order_just_finished") }}
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- offline message dialog -->
      <offline-message-component />
    </v-main>
  </v-app>
</template>

<script>
import { createNamespacedHelpers, mapState } from "vuex";
import OfflineMessageComponent from "./components/Alerts/OfflineMessageComponent";
import ENDPOINTS from "@/plugins/axios/endpoints";

export default {
  name: "App",
  data: () => ({
    timer: null,
    is_error: false,
    an_order_finished: false
  }),
  components: { OfflineMessageComponent },
  methods: {
    ...createNamespacedHelpers("Order").mapMutations(["waitApprove"]),
    tryAgain() {
      this.is_error = false;
      this.$store.commit("Order/setOrder", {});
      this.$store.commit("Order/setOrdered", []);

      this.$nextTick(() => {
        this.$router.push("/");
        //this.$router.go();
      });
    },
    quit() {
      window.close();
      Window.close();
    },
    async getOrder(order_id, party, table, paid = false) {
      //if (order_id !== undefined && order_id !== "undefined") {
      return await this.$axios
        .get(
          ENDPOINTS.SELF_SERVICE.ORDERS.TEMP_ORDERS.MERGED_ORDERS + order_id,
          {
            params: {
              tableName: table,
              partyName: party,
              paid: paid
            }
          }
        )
        .then(res => {
          if (res.status === 200 && typeof res.data === "object") {
            if (party.includes("unpaid"))
              this.$store.commit("Order/setUnpaidItems", res.data.data.items);
            else this.$store.commit("Order/pushOrder", res.data.data);
          }

          this.$forceUpdate();
        })
        .catch(() => {});
      //} else return new Promise(resolve => resolve(true));
    }
  },
  computed: {
    ...mapState(["Order"]),
    ...createNamespacedHelpers("Config").mapGetters([
      "FontFamily",
      "is_available",
      "defaultLanguage"
    ]),
    ...createNamespacedHelpers("Loader").mapGetters(["isLoading"]),
    ...createNamespacedHelpers("Order").mapGetters([
      "order",
      "ordered",
      "unpaidOrder",
      "orders"
    ]),
    ...createNamespacedHelpers("Table").mapGetters({
      party: "party",
      table_name: "table"
    }),
    current_domain() {
      const domain = window.location.hostname;
      return domain === "localhost"
        ? "don-omar.virtueller-kellner.tablebee.de"
        : domain;
    },
    hasUnpaidOrder() {
      return (
        this.unpaidOrder !== null &&
        this.unpaidOrder !== undefined &&
        this.unpaidOrder !== "" &&
        this.unpaidOrder.length >= 16
      );
    },
    hasPaidOrder() {
      if (Array.isArray(this.orders) && this.orders.length > 0) return true;
      return !!(this.order && this.order.id && this.order.id.length >= 16);
    },
    hasOrder() {
      if (Array.isArray(this.orders) && this.orders.length > 0) return true;
      if (this.order && this.order.id && this.order.id.length >= 16)
        return true;
      return (
        this.unpaidOrder !== null &&
        this.unpaidOrder !== undefined &&
        this.unpaidOrder !== "" &&
        this.unpaidOrder.length >= 16
      );
    }
  },
  /*sockets: {
    'tablebee.checkedOut': function (data) {
      if (self.hasOrder)
        self.finishOrder(data.orderUUID).then(() => {
          self.$forceUpdate();
        })
    }
  },*/
  mounted() {
    //set default fallbackLocale
    this.$i18n.vm.fallbackLocale = this.defaultLanguage;

    if (localStorage.getItem("lang")?.length > 0)
      this.$i18n.locale = localStorage.getItem("lang");
    else this.$i18n.locale = this.defaultLanguage;

    this.$forceUpdate();

    const self = this;
    //CONNECT TO SOCKET SERVER
    this.$socket.io.opts.query = {
      tablebeeOrigine: this.current_domain
    };
    this.$socket.open();

    //is order finished
    this.sockets.subscribe("tablebee.checkedOut", async data => {
      if (self.hasOrder) {
        await self.$store.commit("Order/finishOrder", data.orderUUID);
        if (self.hasOrder) {
          this.an_order_finished = true;
        } else {
          this.is_error = true;
        }
        self.$forceUpdate();
      }
    });

    //is order approved
    this.sockets.subscribe("tablebee.orderApproved", async data => {
      if (
        data.tableName === this.table_name &&
        (data.party === this.party ||
          data.party.includes(`${this.party}_unpaid`))
      ) {
        this.waitApprove(false);
      }
    });

    //is order updated
    this.sockets.subscribe("tablebee.orderUpdated", async data => {
      if (
        data?.table?.name === this.table_name &&
        (data?.table?.party === this.party ||
          data?.table?.party.includes(`${this.party}_unpaid`))
      ) {
        if (
          data?.table?.party.includes(`${this.party}_unpaid`) &&
          this.hasUnpaidOrder
        ) {
          await this.getOrder(
            data.uuid,
            data.table.party,
            data.table.name,
            true
          );
        } else if (data?.table?.party === this.party && this.hasPaidOrder)
          await this.getOrder(data.uuid, data.table.party, data.table.name);
      }
    });
    /**
     * check if the current customer has an order then check if this order is finished or not
     */
  }
};
</script>
