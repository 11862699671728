import multiguard from "vue-router-multiguard";

//import localbee table middlewares
import config from "@/middlewares/localBeeTable/config/index";
import order from "@/middlewares/order/index";
import tableMiddleware from "@/middlewares/tableMiddleware";

const routes = [
  /*start localbee_tables*/
  {
    path: "/" /*path: "/"*/ /*table: the name/id of scanned table*/,
    name: "TableScanner",
    component: require("@/views/localbee_table/Table/index").default,
    beforeEnter: multiguard([config])
  },
  {
    path: "/privacy" /*path: "/"*/ /*table: the name/id of scanned table*/,
    name: "Privacy",
    component: require("@/views/localbee_table/privacy/index").default,
    beforeEnter: multiguard([config])
  },
  {
    path: "/allergens" /*path: "/"*/ /*table: the name/id of scanned table*/,
    name: "Allergens",
    component: require("@/views/localbee_table/allergens/index").default,
    beforeEnter: multiguard([config])
  },

  {
    path: "/table/:table" /*table: the name/id of scanned table*/,
    name: "Start",
    component: require("@/views/localbee_table/Start/index.vue").default,
    beforeEnter: multiguard([config])
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import("@/views/localbee_table/Menu/index.vue"),
    beforeEnter: multiguard([config, order, tableMiddleware])
  },
  {
    path: "/missing-config",
    name: "MissingConfig",
    component: () => import("@/views/localbee_table/MissingConfig/index")
  },
  {
    path: "/checkout/:status?",
    name: "Checkout",
    component: () => import("@/views/localbee_table/Checkout/index.vue"),
    beforeEnter: multiguard([config, tableMiddleware])
  },
  {
    path: "/gift-card",
    name: "GiftCrd",
    component: () => import("@/views/localbee_table/GiftCard/index.vue")
  },
  {
    path: "/success/:id",
    name: "Success",
    component: () => import("@/views/localbee_table/Success/index.vue"),
    beforeEnter: multiguard([config, tableMiddleware])
  }
  /*end localbee_tables*/
];

export default routes;
