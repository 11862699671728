/**
 * @author Omar IZEM
 * @description Order module allow us to manage orders like selected items, update order ...
 *
 * @Items: selected items by customer (open items or we can sai items are selected but not ordered yet)
 * @Ordered: main order ordered items (payed items => user already ordered items and he's waiting for preparation)
 * @Order: (for main order) an object contain all order info like id, date, status ...
 * @Item: selected item => allow us to apply some operations like change qty, set note ....
 * @Note: global order note
 * @Current: allow us to remember selected item (not obligatory to be in the card like @Item)
 * @GiftCard: scanned gift card
 * @Orders: array of all paid orders objects
 * @waitingApprove: a flag to indicate if this party is waiting for order to be approved ( is this the last order in the current table )
 * */

const Order = {
  namespaced: true,
  state: {
    /**
     * the main order object
     */
    Order: {},
    unpaid_order: null /** unpaid order id */,
    /**
     * main order items
     */
    Ordered: [],
    Items: /*JSON.parse(localStorage.getItem("items")) ||*/ [],
    unpaid_items: [],
    Item: null,
    Note: "",
    Current: null,
    GiftCard: null,
    Orders: [],
    waitingApprove: false
  },
  getters: {
    isWaitingApprove: state => state.waitingApprove,
    /**
     * @param {float} price
     * @param {float} discount
     * @return {function(*=, *=)}
     */
    discountedPrice() {
      return (price, discount) => {
        return (
          parseFloat(price) - parseFloat(price) * (parseFloat(discount) / 100)
        );
      };
    },
    orders: stat => stat.Orders,
    unpaidItems: state => state.unpaid_items,
    unpaidOrder: state => state.unpaid_order,
    /*get scanned gift card*/
    giftCard: state => state.GiftCard || {},
    /*get order details*/
    order: state => state.Order,
    /*get ordered items (if exists)*/
    ordered: state => state.Ordered,
    /*get selected items (card content)*/
    card: state => state.Items,
    /*check if card empty*/
    isCardEmpty: state =>
      (Array.isArray(state.Items) && state.Items.length === 0) ||
      !Array.isArray(state.Items),
    itemTotalPrice: () => {
      return item => {
        return (
          item.quantity *
          parseFloat(
            parseFloat(
              Object.prototype.hasOwnProperty.call(item, "size") &&
                item.size !== null
                ? item.size.finalizedPrice /*item.size.sellPrice*/
                : item.item.finalizedPrice
            ) +
              //item extras total price
              (!Array.isArray(item.extras)
                ? 0
                : item.extras.reduce(
                    (extras_acc, extra) =>
                      extras_acc +
                      parseFloat(
                        extra.finalizedPrice /*extra.sellingPrice*/ || 0
                      ) *
                        extra.quantity,
                    0
                  ))
          ).toFixed(2)
        );
      };
    },
    /*calculate card total*/
    total: (state, getters) => {
      if (getters.isCardEmpty) return 0;

      /*calculate sum*/
      let sum = 0;
      sum = state.Items.reduce(
        (acc, item) => acc + parseFloat(getters.itemTotalPrice({ ...item })),
        0
      );

      if (state.GiftCard && parseFloat(state.GiftCard.balance) > 0)
        sum -= parseFloat(state.GiftCard.balance);
      //to avoid negative values
      if (sum < 0) sum = 0;
      return parseFloat(sum).toFixed(2);
    },
    /*check if item is selected //by id*/
    checkItem: state => id => {
      const search = state.Items.find(elt => elt.item.id === id);

      return typeof search === "object";
    },
    /*get selected item*/
    selectedItem: state => state.Item || {},
    /*check if this item is the current selected item*/
    isCurrent: state => id => {
      if (!state.Current || !state.Current.item) return false;
      return state.Current.item.id === id;
    },
    current: state => {
      return state.Current;
    },
    currentQuantity: state => {
      if (!(state.Current && state.Current.item)) return 0;

      return state.Current.quantity;
    },
    /*get order note*/
    note: state => state.Note,
    /**
     * calc total price of select extras
     * @param state
     * @returns {number|T}
     */
    currentExtrasTotal: state => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      ) {
        return state.Current.extras.reduce(
          (accumulator, value) =>
            accumulator + parseFloat(value.finalizedPrice) * value.quantity,
          0
        );
      }
      return 0;
    },
    /**
     * return all selected extras for the current item
     * @param state
     * @returns {T[]|*[]}
     */
    currentExtras: state => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      )
        return state.Current.extras.map(elt => elt);

      return [];
    },
    currentMealSize: state => {
      if (
        typeof state.Current === "object" &&
        state.Current.size !== null &&
        typeof state.Current.size === "object"
      )
        return { ...state.Current.size };

      return null;
    },
    isExtrasSelected: state => payload => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      ) {
        //let's search for this item
        const search = state.Current.extras.find(
          elt => elt.id === payload.id && elt.tab_id === payload.tab_id
        );
        return !!search;
      }

      return false;
    },
    totalQuantityOfSelectedExtrasInTab: state => tab => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      ) {
        //let's search for this item
        const search = state.Current.extras.filter(
          elt => elt.tab_id === tab.id
        );
        /*calculate sum*/
        let sum = 0;
        sum = search.reduce((acc, value) => acc + value.quantity, 0);
        return sum;
      } else return 0;
    },
    ExtrasQuantity: state => payload => {
      if (
        typeof state.Current === "object" &&
        Array.isArray(state.Current.extras)
      ) {
        //let's search for this item
        const search = state.Current.extras.find(
          elt => elt.id === payload.id && elt.tab_id === payload.tab_id
        );

        if (search) return search.quantity;
        else return 0;
      }

      return 0;
    }
  },
  mutations: {
    waitApprove(state, payload) {
      state.waitingApprove = payload === true;
    },
    pushOrder(state, payload) {
      if (!(typeof payload === "object")) return;
      if (!Array.isArray(state.Orders)) this.Orders = [];

      /**
       * check if this order is not already exists
       */
      let search = state.Orders.find(({ id }) => payload.id === id);
      if (search) return;

      /**
       * add the new order
       */
      state.Orders.push({ ...payload });
    },
    finishOrder(state, order_id) {
      return new Promise((resolve, reject) => {
        try {
          if (!Array.isArray(state.Orders)) this.Orders = [];
          /**
           * let's check if the finished order is unpaid order
           */
          if (
            state.unpaid_order !== null &&
            state.unpaid_order !== undefined &&
            state.unpaid_order !== "" &&
            state.unpaid_order.length >= 16 &&
            state.unpaid_order === order_id
          ) {
            state.unpaid_order = null;
            state.unpaid_items = [];
            resolve(true);
          } else {
            /**
             * check if this order is not already exists
             */
            let search = state.Orders.find(({ id }) => order_id === id);

            if (search) {
              const index = state.Orders.indexOf(search);
              if (index > -1) {
                state.Orders.splice(index, 1);
              }
            }
            resolve(true);
          }

          reject(false);
        } catch (e) {
          reject(false);
        }
      });
    },
    initialState(state) {
      state.Order = {};
      state.unpaid_order = null;
      state.Ordered = [];
      state.Items = [];
      state.unpaid_items = [];
      state.Item = null;
      state.Note = "";
      state.Current = null;
      state.GiftCard = null;
      state.Orders = [];
      state.waitingApprove = false;
    },
    /**
     *
     * @param {JSON} state
     * @param {integer} payload
     */
    setUnpaidOrder(state, payload) {
      state.unpaid_order = payload;
    },
    /**
     * set scanned gift card
     * @param state
     * @param payload
     */
    setGiftCard: (state, payload) => {
      state.GiftCard = payload;
      /*localStorage.setItem("GiftCard", JSON.stringify(payload));*/
    },
    /**
     * @description set order object
     * @param state
     * @param order
     */
    setOrder: (state, order) => {
      state.Order = order;
    },
    /*set ordered array*/
    setOrdered: (state, ordered = []) => {
      state.Ordered = ordered;
    },
    /*set unpaid items array*/
    setUnpaidItems: (state, ordered = []) => {
      state.unpaid_items = ordered;
    },
    /*push item in the ordered array*/
    pushOrdered: (state, item = {}) => {
      if (!Array.isArray(state.Ordered)) state.Ordered = [];

      if (!Object.prototype.isObject(item)) return;

      state.Ordered.push(item);
    },
    /*set Selected items (card)*/
    setCard: (state, items = []) => {
      state.Items = items;

      //add this items to locale storage
      localStorage.setItem("items", JSON.stringify(state.Items));
    },
    /*push single item to card*/
    addToCard: (state, payload = { item: {}, quantity: 1 }) => {
      if (!Array.isArray(state.Items)) state.Items = [];

      if (!(typeof payload === "object")) return;
      let item = { ...payload.item },
        quantity = payload.quantity > 0 ? payload.quantity : 1;

      /**
       * let's assign extras to this item
       */
      let extras = [];
      let size = null;
      // 1 -- check if this item is the current selected item
      if (!Object.prototype.hasOwnProperty.call(item, "item"))
        if (window.store.getters["Order/isCurrent"](item.id)) {
          extras = window.store.getters["Order/currentExtras"];
          size = window.store.getters["Order/currentMealSize"];
        }

      /*check if the item is already selected*/
      const search = state.Items.find(elt => {
        if (
          Array.isArray(extras) &&
          extras.length > 0 &&
          Array.isArray(elt.extras) &&
          Object.prototype.hasOwnProperty.call(elt, "size") &&
          typeof elt.size === "object" &&
          !!size &&
          Object.keys(size).length > 0
        ) {
          return (
            elt.item.id === item.id &&
            JSON.stringify(extras) === JSON.stringify(elt.extras) &&
            size.id === elt.size.id
          );
        } else if (
          (!Array.isArray(extras) ||
            (Array.isArray(extras) && extras.length === 0)) &&
          !Array.isArray(elt.extras) &&
          size !== null &&
          elt.size
        ) {
          elt.item.id === item.id && size.id === elt.id;
        }

        if (
          Array.isArray(extras) &&
          extras.length > 0 &&
          Array.isArray(elt.extras) &&
          size === null
        ) {
          return (
            elt.item.id === item.id &&
            JSON.stringify(extras) === JSON.stringify(elt.extras)
          );
        } else if (
          Array.isArray(elt.extras) &&
          elt.extras.length > 0 &&
          Object.prototype.hasOwnProperty.call(item, "item") &&
          Array.isArray(item.extras) &&
          size === null
        )
          return (
            elt.item.id === item.item.id &&
            JSON.stringify(elt.extras) === JSON.stringify(item.extras)
          );

        if (Object.prototype.hasOwnProperty.call(item, "item"))
          return elt.item.id === item.item.id;
        else return elt.item.id === item.id;
      });

      if (search === undefined)
        /*new item*/
        state.Items.push({
          quantity: quantity,
          discount: item.discount,
          item: item,
          extras: extras,
          size: size
        });
      else {
        /*update quantity*/
        const index = state.Items.indexOf(search);

        /*break if no item found*/
        if (index === -1) return;

        if (Object.prototype.hasOwnProperty.call(item, "item"))
          //will run when we want to increase qty
          Object.assign(state.Items[index], {
            note: search.note,
            discount: search.discount,
            quantity: search.quantity + quantity,
            item: item.item,
            size: item.size
          });
        else
          Object.assign(state.Items[index], {
            note: search.note,
            discount: search.discount,
            quantity: search.quantity + quantity,
            item: item,
            size: search.size
          });
      }
    },
    /*remove item from card*/
    removeItem: (state, item = {}) => {
      /*search item then get it position/index*/
      const search = state.Items.find(elt => {
        if (
          Array.isArray(item.extras) &&
          item.extras.length > 0 &&
          Array.isArray(elt.extras)
        ) {
          return (
            elt.item.id === item.item.id &&
            JSON.stringify(item.extras) === JSON.stringify(elt.extras)
          );
        }
        if (Object.prototype.hasOwnProperty.call(item, "item"))
          return elt.item.id === item.item.id;
        else return elt.item.id === item.id;
      });

      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) return;

      /*slice the item*/
      state.Items.splice(index, 1);

      if (state.Items.length === 0) state.Items = [];
      //add this items to locale storage
      /*localStorage.setItem("items", JSON.stringify(state.Items));*/
    },
    /*change quantity*/
    changeQty: (state, payload = { item: {}, quantity: 1 }) => {
      const item = { ...payload.item },
        quantity = payload.quantity,
        size = { ...payload.size };

      /*apply update only if quantity > 0*/
      if (quantity < 1) return;

      /*search item*/
      const search = state.Items.find(elt => {
        if (Object.prototype.hasOwnProperty.call(item, "item")) {
          //check size + extras
          if (
            Array.isArray(elt.extras) &&
            elt.extras.length > 0 &&
            Array.isArray(item.extras) &&
            Object.prototype.hasOwnProperty.call(elt, "size") &&
            typeof elt.size === "object" &&
            !!size &&
            Object.keys(size).length > 0
          ) {
            return (
              elt.item.id === item.item.id &&
              JSON.stringify(elt.extras) === JSON.stringify(item.extras) &&
              size.id === elt.size.id
            );
          }

          //check only extras
          if (
            Array.isArray(elt.extras) &&
            elt.extras.length > 0 &&
            Array.isArray(item.extras)
          ) {
            return (
              elt.item.id === item.item.id &&
              JSON.stringify(elt.extras) === JSON.stringify(item.extras)
            );
          }

          //check only size
          if (
            Object.prototype.hasOwnProperty.call(elt, "size") &&
            typeof elt.size === "object" &&
            !!size &&
            Object.keys(size).length > 0
          ) {
            return (
              elt.item.id === item.item.id &&
              JSON.stringify(elt.extras) === JSON.stringify(item.extras)
            );
          }

          return elt.item.id === item.item.id;
        } else {
          //TODO: to update later
        }

        /* if (Object.prototype.hasOwnProperty.call(item, "item"))
                                     return elt.item.id === item.item.id;
                                 else return elt.item.id === item.id;*/
      });

      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) return;

      /*update quantity*/
      if (Object.prototype.hasOwnProperty.call(item, "item"))
        Object.assign(state.Items[index], {
          quantity: quantity,
          item: item.item
        });
      else
        Object.assign(state.Items[index], {
          quantity: quantity,
          item: item
        });

      //add this items to locale storage
      /*localStorage.setItem("items", JSON.stringify(state.Items));*/
    },
    /*select item to use it as current item*/
    selectItem: (state, item) => {
      /*get requested item*/
      const search = state.Items.find(elt => {
        if (
          Array.isArray(elt.extras) &&
          elt.extras.length > 0 &&
          Object.prototype.hasOwnProperty.call(item, "item") &&
          Array.isArray(item.extras)
        )
          return (
            elt.item.id === item.item.id &&
            JSON.stringify(elt.extras) === JSON.stringify(item.extras)
          );

        if (Object.prototype.hasOwnProperty.call(item, "item"))
          return elt.item.id === item.item.id;
        else return elt.item.id === item.id;
      });
      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) {
        state.Item = null;
      }

      state.Item = Object.assign({}, search);
    },
    /**
     * set current item
     * @param state
     * @param item
     */
    selectCurrent: (state, item) => {
      if (!(typeof item === "object")) return;

      if (state.Current && state.Current.item.id === item.id) {
        state.Current = null;
        return;
      }
      state.Current = Object.assign({
        quantity: 1,
        discount: item.discount,
        item: Object.assign({}, item),
        extras: null,
        size: null
      });
    },
    setCurrentMealSize: (state, size) => {
      //check if there's item already selected
      if (!state.Current) return;

      //check size type
      if (typeof size === "object" || size === null) {
        state.Current.size = size;
        state.Current.extras = [];
      }
    },
    setCurrentQuantity: (state, quantity) => {
      //check if this quantity is valid
      if (quantity <= 0) return;

      //check if current item has value
      if (!(state.Current && state.Current.item)) return;

      //set new quantity
      state.Current.quantity = quantity;
    },
    /**
     * add extras
     * @param state
     * @param extra object
     */
    pushExtrasToCurrent: (state, extra = {}) => {
      //check if there's item already selected
      if (!state.Current) return;

      //reserve space for extras array
      if (!Array.isArray(state.Current.extras)) state.Current.extras = [];

      //check if this extras is already selected
      const search = state.Current.extras.find(
        elt => elt.id === extra.id && elt.tab_id === extra.tab_id
      );

      if (search) {
        //extras already selected
        const index = state.Current.extras.indexOf(search);

        ++state.Current.extras[index].quantity;

        return;
      }

      //add new extras
      state.Current.extras.push({
        id: extra.id,
        tab_id: extra.tab_id,
        name: extra.name,
        finalizedPrice: extra.finalizedPrice,
        quantity: 1
      });
    },
    removeExtraFromCurrent: (state, payload) => {
      if (typeof payload !== "object") return;
      if (
        !state.Current ||
        (state.Current && !Array.isArray(state.Current.extras))
      )
        return;
      //check if this extra is already exist
      const search = state.Current.extras.find(
        extra => extra.id === payload.id && extra.tab_id === payload.tab_id
      );

      //extra exist
      if (search) {
        const index = state.Current.extras.indexOf(search);

        --state.Current.extras[index].quantity;

        if (state.Current.extras[index].quantity <= 0)
          state.Current.extras.splice(index, 1);
      }
    },
    /*clear card*/
    clearCard: state => {
      state.Items = [];
      state.Current = null;
      state.Item = null;
      /* localStorage.removeItem("items");
                                                                                                                         localStorage.removeItem("GiftCard");*/
    },
    clearOrder(state) {
      state.order = {};
      state.unpaid_order = null;
    },
    /*set order note*/
    setOrderNote: (state, note) => {
      state.Note = note;
    }
  },
  actions: {
    /*set item note*/
    itemNote: ({ state, getters }, note = "") => {
      /*let get selected item*/
      const item = getters.selectedItem || null;
      /*get requested item*/
      const search = state.Items.find(elt => {
        if (
          Array.isArray(elt.extras) &&
          elt.extras.length > 0 &&
          Object.prototype.hasOwnProperty.call(item, "item") &&
          Array.isArray(item.extras)
        )
          return (
            elt.item.id === item.item.id &&
            JSON.stringify(elt.extras) === JSON.stringify(item.extras)
          );

        if (Object.prototype.hasOwnProperty.call(item, "item"))
          return elt.item.id === item.item.id;
        else return elt.item.id === item.id;
      });
      const index = state.Items.indexOf(search);

      /*break if no item found*/
      if (index === -1) return;

      /*update note*/
      let tmp = { ...state.Items[index] };
      tmp.note = note;
      state.Items[index] = { ...tmp };
      state.Item = null;
    }
  }
};

export default Order;
