import ENDPOINTS from "@/plugins/axios/endpoints";
import moment from "moment";

const Color = require("color");
export default {
  namespaced: true,
  state: {
    themeColors: null,
    textColors: null,
    images: null,
    fontFamily: null,
    hide_product_details: false,
    messages: null,
    is_paypal_configured: false,
    Privacy: "",
    allergens: "",
    global_product_info: null,
    openingTimes: [],
    status: true, // service status
    payments: [], //list of activated payments
    show_allergens: false,
    show_default_product_info: false,
    show_product_description: false,
    default_product_description: "",
    hide_waiter_button: false,
    can_order_as_group: false,
    productCardStyle: null,
    enabledLanguages: null,
    default_language: "de",
    selectPaymentMsg: ""
  },
  getters: {
    selectPaymentMsg: state => state.selectPaymentMsg,
    defaultLanguage: state => state.default_language || "de",
    languages: state => {
      if (
        Array.isArray(state.enabledLanguages) &&
        state.enabledLanguages.length > 0
      )
        return [...state.enabledLanguages];
      else return ["de"];
    },
    productCardStyle: state => {
      let style = {};
      if (state.productCardStyle) {
        if (
          Object.prototype.hasOwnProperty.call(
            state.productCardStyle,
            "border_size"
          )
        )
          style.border = `${state.productCardStyle.border_size}px solid !important`;
        if (
          Object.prototype.hasOwnProperty.call(
            state.productCardStyle,
            "border_color"
          )
        )
          style[
            "border-color"
          ] = `${state.productCardStyle.border_color} !important`;
        if (
          Object.prototype.hasOwnProperty.call(
            state.productCardStyle,
            "border_radius"
          )
        )
          style[
            "border-radius"
          ] = `${state.productCardStyle.border_radius}px !important`;
      }
      return { ...style };
    },
    can_order_as_group: state => state.can_order_as_group,
    show_allergens: state => state.show_allergens,
    show_default_product_info: state => state.show_default_product_info,
    show_product_description: state => state.show_product_description,
    default_product_description: state => state.default_product_description,
    hide_waiter_button: state => state.hide_waiter_button,
    payments: state => state.payments || [],
    openingTimes: state => state.openingTimes,
    status: state => state.status,
    is_available: (state, getters) => {
      return state.status && getters.isStillOpen;
    },
    isStillOpen: (state, getters) => {
      let today = new Date().getDay() - 1 === -1 ? 6 : new Date().getDay() - 1;
      let now = moment();
      //  console.log('before', today)
      if (getters.openingTimes && getters.openingTimes.length > 0) {
        // console.log('in goood',)
        if (getters.openingTimes.length > today) {
          //console.log('still not bad',)
          if (getters.openingTimes[today].open) {
            //console.log('but',)
            let start1 =
              getters.openingTimes[today]["shift1"].start !== "00:00:00.000000"
                ? getters.openingTimes[today]["shift1"].start.substring(0, 5)
                : null;
            let end1 =
              getters.openingTimes[today]["shift1"].end !== "00:00:00.000000"
                ? getters.openingTimes[today]["shift1"].end.substring(0, 5)
                : null;
            let start2 =
              getters.openingTimes[today]["shift2"].start !== "00:00:00.000000"
                ? getters.openingTimes[today]["shift2"].start.substring(0, 5)
                : null;
            let end2 =
              getters.openingTimes[today]["shift2"].end !== "00:00:00.000000"
                ? getters.openingTimes[today]["shift2"].end.substring(0, 5)
                : null;

            if (getters.openingTimes[today]["shift1"].open && start1 && end1) {
              if (
                now.isBetween(
                  moment(start1, "HH:mm"),
                  moment(end1, "HH:mm")
                ) /* || now.isBefore(moment(end1, "HH:mm"))*/
              ) {
                return true;
              }
            }
            //console.log('shift 2 ddata: ', start2, end2)
            if (getters.openingTimes[today]["shift2"].open && start2 && end2) {
              //  console.log("shift 2 open ", now.isBetween(moment(start2, "HH:mm"), moment(end2, "HH:mm")) || now.isBefore(moment(end2, "HH:mm")), moment(start2, "HH:mm"), moment(end2, "HH:mm"))
              if (
                now.isBetween(
                  moment(start2, "HH:mm"),
                  moment(end2, "HH:mm")
                ) /* || now.isBefore(moment(end2, "HH:mm"))*/
              ) {
                return true;
              }
            }
          }
        }
      }
      return false;
    },
    privacy: state => state.Privacy,
    allergens: state => state.allergens,
    isPaypalConfigured: state => parseInt(state.is_paypal_configured),
    globalProductInfo: state => state.global_product_info,
    ThemeColors: state => state.themeColors || {},
    TextColors: state => state.textColors || {},
    Images: state => state.images,
    FontFamily: state => state.fontFamily,
    Messages: state => state.messages,
    hideProductDetails: state => state.hide_product_details,
    isConfigured: state => {
      if (state.themeColors) {
        if (!state.themeColors.primary) return false;
        if (!state.themeColors.secondary) return false;
        if (!state.themeColors.background) return false;
        if (!state.themeColors.accent) return false;
        if (!state.themeColors.product_card) return false;
      } else return false;

      if (state.textColors) {
        if (!state.textColors.product_name) return false;
        if (!state.textColors.product_description) return false;
      } else return false;

      if (state.messages) {
        if (!state.messages.start_page) return false;
        if (!state.messages.ask_for_name_page) return false;
      } else return false;

      return !!state.fontFamily;
    }
  },
  mutations: {
    SET_SELECT_PAYMENT_MSG(state, payload) {
      state.selectPaymentMsg = payload;
    },
    setDefaultLanguage(state, payload) {
      if (typeof payload === "string" && payload?.length > 1)
        state.default_language = payload?.toLowerCase();
      else state.default_language = "de";
    },
    LANGUAGES(state, payload) {
      if (Array.isArray(payload))
        state.enabledLanguages = payload.map(lang => lang.toLowerCase());
    },
    PRODUCT_CARD_STYLE(state, payload) {
      if (typeof payload === "object") state.productCardStyle = { ...payload };
    },
    CAN_ORDER_AS_GROUP(state, payload) {
      state.can_order_as_group = payload;
    },
    HIDE_WAITER_BUTTON(state, payload) {
      state.hide_waiter_button = payload;
    },
    ALLERGENS_VISIBILTY(state, payload) {
      state.show_allergens = payload;
    },
    DEFAULT_PRODUCT_INFO_VISIBILTY(state, payload) {
      state.show_default_product_info = payload;
    },
    PRODUCT_DESCRIPTION_VISIBILTY(state, payload) {
      state.show_product_description = payload;
    },
    DEFAULT_PRODUCT_DESCRIPTION(state, payload) {
      state.default_product_description = payload;
    },
    PAYMENTS(state, payload) {
      if (!Array.isArray(payload)) return;
      state.payments = payload;
    },
    OPENING_TIMES(state, payload) {
      if (Array.isArray(payload)) state.openingTimes = payload;
      else state.openingTimes = [];
    },
    STATUS(state, payload) {
      state.status = payload;
    },
    THEME_COLORS(state, payload) {
      if (typeof payload !== "object") {
        state.themeColors = null;
        return;
      }

      state.themeColors = { ...payload };

      //update vuetify colors
      if (payload.primary && payload.primary !== "Array")
        window.vuetify.theme.themes.light.primary.base = Color(
          payload.primary
        ).hex();
      else window.vuetify.theme.themes.light.primary.base = "#F17800";

      //secondary
      if (payload.secondary && payload.secondary !== "Array")
        window.vuetify.theme.themes.light.secondary = Color(
          payload.secondary
        ).hex();
      else window.vuetify.theme.themes.light.secondary = "#F4E9E3";

      //bg
      if (payload.background && payload.background !== "Array")
        window.vuetify.theme.themes.light.bg = Color(payload.background).hex();
      else window.vuetify.theme.themes.light.bg = "#F8F4F1";

      //accent
      if (payload.accent && payload.accent !== "Array")
        window.vuetify.theme.themes.light.accent = Color(payload.accent).hex();
      else window.vuetify.theme.themes.light.accent = "#546BBE";

      //product card
      if (payload.product_card && payload.product_card !== "Array")
        window.vuetify.theme.themes.light.product_card = Color(
          payload.product_card
        ).hex();
      //else window.vuetify.theme.themes.light.product_card=
    },
    TEXT_COLORS(state, payload) {
      if (typeof payload !== "object") {
        state.textColors = null;
        return;
      }

      state.textColors = { ...payload };

      window.vuetify.theme.themes.light.product_name = Color(
        payload.product_name
      ).hex();
      window.vuetify.theme.themes.light.product_description = Color(
        payload.product_description
      ).hex();
    },
    IMAGES(state, payload) {
      if (typeof payload !== "object") {
        state.images = null;
        return;
      }

      state.images = { ...payload };
    },
    HIDE_PRODUCT_DETAILS(state, payload) {
      state.hide_product_details = payload;
    },
    FONT_FAMILY(state, payload) {
      state.fontFamily = payload;
    },
    MESSAGES(state, payload) {
      if (typeof payload !== "object") {
        state.messages = null;
        return;
      }
      state.messages = { ...payload };
      state.global_product_info = payload.global_product_info;
    },
    IS_PAYAPL_CONFIGURED(state, payload) {
      state.is_paypal_configured = payload;
    },
    PRIVACY(state, payload) {
      state.Privacy = payload;
    },
    ALLERGENS(state, payload) {
      state.allergens = payload;
    }
  },
  actions: {
    loadConfig({ commit }) {
      return new Promise((resolve, reject) => {
        window.store.commit("Loader/startLoading");
        window.axios
          .get(ENDPOINTS.SELF_SERVICE.CONFIG.GET)
          .then(res => {
            if (res && res.data) {
              commit("THEME_COLORS", res.data.colors);
              commit("MESSAGES", res.data.messages);
              commit("PRIVACY", res.data.messages.privacy);
              commit("ALLERGENS", res.data.Allergens);
              commit("IMAGES", res.data.images);
              commit(
                "HIDE_PRODUCT_DETAILS",
                res.data.text.hide_product_details
              );
              commit("FONT_FAMILY", res.data.text.font_family);
              commit("TEXT_COLORS", res.data.text.colors);
              commit("IS_PAYAPL_CONFIGURED", res.data.isPaypalAvailable);
              commit("OPENING_TIMES", res.data.openingTimes);
              commit("STATUS", res.data.status);
              commit("PAYMENTS", res.data.payments);
              commit("ALLERGENS_VISIBILTY", res.data.show_allergens);
              commit(
                "DEFAULT_PRODUCT_INFO_VISIBILTY",
                res.data.show_default_product_info
              );
              commit(
                "PRODUCT_DESCRIPTION_VISIBILTY",
                res.data.show_product_description
              );
              commit(
                "DEFAULT_PRODUCT_DESCRIPTION",
                res.data.default_product_description
              );

              commit(
                "HIDE_WAITER_BUTTON",
                res.data.hide_waiter_button === true
              );

              commit(
                "CAN_ORDER_AS_GROUP",
                res.data.enable_order_as_group === true
              );

              commit("PRODUCT_CARD_STYLE", res.data.productCard);

              commit("LANGUAGES", res.data.enabled_languages);
              commit("setDefaultLanguage", res.data.default_language);

              commit("SET_SELECT_PAYMENT_MSG", res.data.selectPaymentMsg);
            }
            resolve();
          })
          .catch(err => {
            reject(err);
          })
          .finally(() => {
            window.store.commit("Loader/stopLoading");
          });
      });
    }
  }
};
