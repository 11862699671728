import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import "@/plugins/bootstrap-vue";
import "@/plugins/axios/index";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import "@/plugins/currency";
import VueI18n from "vue-i18n";
import messages from "./i18n";

import VueFormWizard from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
//register qr scanner globally
import VueQrcodeReader from "vue-qrcode-reader";
/**flags */
import FlagIcon from "vue-flag-icon";

import VueSwal from "vue-swal";
// --- SocketIO ---
import VueSocketIO from "vue-socket.io";

let socketServerIP = process.env.VUE_APP_SOCKET_SERVER_HOST;
Vue.use(
  new VueSocketIO({
    debug: true,
    connection: socketServerIP,
    options: {
      autoConnect: false
    }
  })
);

Vue.use(VueSwal);

Vue.use(VueFormWizard);

Vue.use(VueQrcodeReader);

Vue.config.productionTip = false;

/**internationalization **/

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: localStorage.getItem("lang") || "de",
  fallbackLocale: "de",
  silentTranslationWarn: true, //DISABLE WARNINGS
  messages
});

Vue.use(FlagIcon);
let instance = new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

window.vuetify = instance.$vuetify;
