const ENDPOINTS = {
  SELF_SERVICE: {
    NOTIFY: "/notification",
    CONFIG: {
      GET: "config"
    },
    CATEGORIES: {
      GET_ALL: "categories"
    },
    ITEMS: {
      GAT_ALL: "items"
    },
    ORDERS: {
      TEMP_ORDERS: {
        APPROVE: "/ApprovePendingOrders",
        GET: "/pendingOrder/",
        MERGED_ORDERS: "/mergedOrder/"
      },
      CREATE: "orders",
      GET: "orders/",
      CHECKORDER: "/check-order-unfinish"
    }
  },
  DELIVERY: {
    CATEGORIES: {
      GET_ALL: "delivery/categories"
    },
    ITEMS: {
      GAT_ALL: "delivery/items"
    },
    ORDERS: {
      CREATE: "delivery/orders",
      GET: "delivery/orders/"
    }
  },
  ERP: {
    MEALTYPES: {
      GET_ALL: "meal-types"
    },
    ALLERGENS: {
      GET_ALL: "allergens"
    },
    GIFTCARDS: {
      GET: id => `/giftcards/${id}`
    }
  },
  TABLEPLANER: {
    TABLE: {
      GET_ALL: "/table-planer/tables",
      GET: uuid => "/table-planer/tables/" + uuid,
      FINDBYUUID: uuid => "/table-planer/tables/uuid/" + uuid
    }
  }
};

window.ENDPOINTS = ENDPOINTS;
export default ENDPOINTS;
